import { createTheme } from "@material-ui/core/styles"
import responsiveFontSizes from "@material-ui/core/styles/responsiveFontSizes"

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#252625"
    },
    secondary: {
      main: "#F7941E"
    },
    background: {
      default: "#2374AB",
      paper: "#EFF1ED"
    },
    text: {
      primary: "#ffffff",
      secondary: "rgba(255,255,255,0.54)"
    }
  }
})

export default responsiveFontSizes(theme)
